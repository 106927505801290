var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    {
      staticClass: "hover:bg-gray-200",
      class: { "bg-gray-200": _vm.template.id === _vm.selectedTemplate.id },
    },
    [
      _c(
        "table-data",
        {
          staticClass: "cursor-pointer",
          nativeOn: {
            click: function ($event) {
              _vm.setShowTemplateModal(true),
                _vm.setSelectedTemplate(_vm.template)
            },
          },
        },
        [_c("p", [_vm._v("#" + _vm._s(_vm.template.id))])]
      ),
      _c(
        "table-data",
        {
          staticClass: "cursor-pointer",
          nativeOn: {
            click: function ($event) {
              _vm.setShowTemplateModal(true),
                _vm.setSelectedTemplate(_vm.template)
            },
          },
        },
        [
          _c("p", { staticClass: "w-52 truncate" }, [
            _vm._v(" " + _vm._s(_vm.template.title) + " "),
          ]),
        ]
      ),
      _c(
        "table-data",
        {
          staticClass: "cursor-pointer",
          nativeOn: {
            click: function ($event) {
              _vm.setShowTemplateModal(true),
                _vm.setSelectedTemplate(_vm.template)
            },
          },
        },
        [
          _c("div", { staticClass: "flex flex-col space-y-1" }, [
            _c("p", {
              staticClass: "w-52 overflow-hidden",
              staticStyle: {
                display: "-webkit-box",
                "-webkit-line-clamp": "5",
                "-webkit-box-orient": "vertical",
              },
              domProps: { innerHTML: _vm._s(_vm.template.body) },
            }),
          ]),
        ]
      ),
      _c(
        "table-data",
        {
          staticClass: "cursor-pointer",
          nativeOn: {
            click: function ($event) {
              _vm.setShowTemplateModal(true),
                _vm.setSelectedTemplate(_vm.template)
            },
          },
        },
        [
          _c("p", [
            _vm._v(" " + _vm._s(_vm.template.appointment_type.name) + " "),
          ]),
        ]
      ),
      _c(
        "table-data",
        {
          staticClass: "cursor-pointer",
          nativeOn: {
            click: function ($event) {
              _vm.setShowTemplateModal(true),
                _vm.setSelectedTemplate(_vm.template)
            },
          },
        },
        [_c("p", [_vm._v(" " + _vm._s(_vm.template.language.name) + " ")])]
      ),
      _c(
        "table-data",
        {
          staticClass: "cursor-pointer",
          nativeOn: {
            click: function ($event) {
              _vm.setShowTemplateModal(true),
                _vm.setSelectedTemplate(_vm.template)
            },
          },
        },
        [
          _c("p", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.template.animal_types
                    .map(function (animal_type) {
                      return animal_type.name
                    })
                    .join(", ")
                ) +
                " "
            ),
          ]),
        ]
      ),
      _c(
        "table-data",
        {
          staticClass: "cursor-pointer",
          nativeOn: {
            click: function ($event) {
              _vm.setShowTemplateModal(true),
                _vm.setSelectedTemplate(_vm.template)
            },
          },
        },
        [
          _c("p", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.format(
                    new Date(_vm.template.updated_at),
                    "HH:mm yyyy-MM-dd"
                  )
                ) +
                " "
            ),
          ]),
        ]
      ),
      _c(
        "table-data",
        { class: { "cursor-pointer": _vm.isAdmin } },
        [
          _vm.isAdmin
            ? _c("font-awesome-icon", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Delete template",
                    expression: "'Delete template'",
                  },
                ],
                staticClass: "text-red-800 hover:text-red-600",
                attrs: { icon: "trash" },
                on: {
                  click: function ($event) {
                    _vm.setShowConfirmationModal(true),
                      _vm.setSelectedTemplate(_vm.template)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }