<template>
  <div><JournalTemplateList :is-admin="true" /></div>
</template>

<script>
import JournalTemplateList from '@/components/journal-templates/JournalTemplateList';

export default {
  components: {
    JournalTemplateList,
  },
};
</script>

<style lang="scss" scoped></style>
