var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-modal",
    {
      attrs: { show: _vm.show },
      on: {
        close: function ($event) {
          !_vm.isAdmin ? _vm.$emit("close") : ""
        },
      },
    },
    [
      _vm._t("default", function () {
        return [
          _c("spinner-overlay", {
            attrs: { color: "light", loading: _vm.loading },
          }),
          _c("div", [
            _c(
              "h2",
              { staticClass: "mb-6 mt-2 text-center text-2xl font-bold" },
              [
                _vm.isAdmin
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.edit
                              ? "Edit " + '"' + _vm.template.title + '"'
                              : "Create template"
                          ) +
                          " "
                      ),
                    ])
                  : _c("span", [_vm._v(_vm._s(_vm.template.title))]),
              ]
            ),
          ]),
          _c(
            "ValidationObserver",
            {
              ref: "observer",
              staticClass: "mb-4 space-y-4 px-4",
              attrs: { tag: "form" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "flex justify-between space-x-2" },
                [
                  _c("ValidationProvider", {
                    staticClass: "w-full md:w-64",
                    attrs: {
                      rules: "required",
                      name: "animal type",
                      tag: "div",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var errors = ref.errors
                          return [
                            _c("v-select", {
                              staticClass:
                                "animal-types w-full rounded text-sm text-gray-800",
                              attrs: {
                                id: "animalTypeIds",
                                name: "animalTypeIds",
                                label: "name",
                                placeholder: "Animal types",
                                multiple: true,
                                disabled: !_vm.isAdmin,
                                loading: !_vm.animalTypes.length,
                                options: _vm.animalTypes,
                                clearable: true,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.animalTypesSelected($event)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  _vm.animalTypes.length
                                    ? {
                                        key: "open-indicator",
                                        fn: function () {
                                          return [
                                            _c("fv-icon", {
                                              attrs: { icon: "chevron-down" },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      }
                                    : null,
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.selectedAnimalTypes,
                                callback: function ($$v) {
                                  _vm.selectedAnimalTypes = $$v
                                },
                                expression: "selectedAnimalTypes",
                              },
                            }),
                            _c("transition", { attrs: { name: "fade" } }, [
                              errors.length
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-1 text-sm font-semibold text-red-800",
                                    },
                                    [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("ValidationProvider", {
                    staticClass: "w-full md:w-64",
                    attrs: { rules: "required", name: "case type", tag: "div" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var errors = ref.errors
                          return [
                            _c("custom-select", {
                              attrs: {
                                id: "appointmentTypeId",
                                name: "appointmentTypeId",
                                size: "sm",
                                "label-size": "sm",
                                "label-attr": "name",
                                "value-attr": "id",
                                searchable: true,
                                options: _vm.filteredAppointmentTypes,
                                value: _vm.form.appointmentTypeId,
                                "data-vv-as": "case type",
                                disabled:
                                  !_vm.form.animalTypeIds || !_vm.isAdmin,
                              },
                              on: {
                                change: function ($event) {
                                  _vm.form.appointmentTypeId = $event
                                },
                              },
                            }),
                            _c("transition", { attrs: { name: "fade" } }, [
                              errors.length
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-1 text-sm font-semibold text-red-800",
                                    },
                                    [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("ValidationProvider", {
                    staticClass: "w-full md:w-64",
                    attrs: { rules: "required", name: "language", tag: "div" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var errors = ref.errors
                          return [
                            _c("custom-select", {
                              attrs: {
                                id: "languageId",
                                name: "languageId",
                                size: "sm",
                                "label-size": "sm",
                                "label-attr": "name",
                                "value-attr": "id",
                                searchable: true,
                                options: _vm.filteredLanguages,
                                value: _vm.form.languageId,
                                "data-vv-as": "language",
                                disabled: !_vm.isAdmin,
                              },
                              on: {
                                change: function ($event) {
                                  _vm.form.languageId = $event
                                },
                              },
                            }),
                            _c("transition", { attrs: { name: "fade" } }, [
                              errors.length
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-1 text-sm font-semibold text-red-800",
                                    },
                                    [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("ValidationProvider", {
                attrs: { rules: "required", name: "title", tag: "div" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var errors = ref.errors
                      return [
                        _vm.isAdmin
                          ? _c("base-input", {
                              attrs: {
                                id: "title",
                                name: "title",
                                placeholder: "Title",
                              },
                              model: {
                                value: _vm.form.title,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "title", $$v)
                                },
                                expression: "form.title",
                              },
                            })
                          : _vm._e(),
                        _c("transition", { attrs: { name: "fade" } }, [
                          errors.length
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "mt-1 text-sm font-semibold text-red-800",
                                },
                                [_vm._v(" " + _vm._s(errors[0]) + " ")]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("ValidationProvider", {
                attrs: { rules: "required", name: "body", tag: "div" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var errors = ref.errors
                      return [
                        _c(
                          "div",
                          { staticClass: "prose w-full max-w-full" },
                          [
                            _vm.isAdmin
                              ? _c("ckeditor", {
                                  attrs: {
                                    id: "body",
                                    editor: _vm.editor,
                                    config: _vm.editorConfig,
                                    name: "body",
                                  },
                                  model: {
                                    value: _vm.form.body,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "body", $$v)
                                    },
                                    expression: "form.body",
                                  },
                                })
                              : _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.form.body),
                                  },
                                }),
                          ],
                          1
                        ),
                        _c("transition", { attrs: { name: "fade" } }, [
                          errors.length
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "mt-1 text-sm font-semibold text-red-800",
                                },
                                [_vm._v(" " + _vm._s(errors[0]) + " ")]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      }),
      _c(
        "div",
        {
          staticClass: "flex w-full justify-end space-x-2 p-2",
          attrs: { slot: "buttons" },
          slot: "buttons",
        },
        [
          _c(
            "base-button",
            {
              attrs: { color: "cancel" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close", false)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.isAdmin ? "Cancel" : "Close") + " ")]
          ),
          _vm.isAdmin
            ? _c(
                "base-button",
                {
                  attrs: {
                    loading: _vm.loading,
                    color: "primary",
                    disabled: _vm.loading,
                  },
                  on: { click: _vm.submit },
                },
                [_vm._v(" " + _vm._s(_vm.edit ? "Update" : "Save") + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }