<template>
  <div class="flex flex-1 flex-col" style="min-height: 50vh">
    <table-container
      :theads="theads"
      :loading="loading"
      style="min-height: 50vh"
    >
      <div
        slot="tableHeader"
        class="flex flex-col justify-between space-y-2 md:w-full md:flex-row md:items-center md:space-y-0"
      >
        <form class="" @submit.prevent="fetch(null)">
          <div
            class="flex flex-col space-y-2 md:flex-row md:items-center md:space-x-3 md:space-y-0"
          >
            <div class="w-full md:w-64">
              <custom-select
                size="sm"
                label-size="sm"
                label-attr="name"
                value-attr="id"
                :searchable="true"
                :loading="!animalTypes.length"
                :options="animalTypes"
                :value="filters.animalTypeId"
                placeholder="Loading ..."
                @change="setAnimalTypeId($event), fetch()"
              />
            </div>

            <div class="w-full md:w-64">
              <custom-select
                size="sm"
                label-size="sm"
                label-attr="name"
                value-attr="id"
                :searchable="true"
                :loading="!appointmentTypes.length"
                :options="filteredAppointmentTypes"
                :value="filters.appointmentTypeId"
                @change="setAppointmentTypeId($event), fetch()"
              />
            </div>

            <div class="w-full md:w-64">
              <custom-select
                size="sm"
                label-size="sm"
                label-attr="name"
                value-attr="id"
                :searchable="true"
                :loading="!languages.length"
                height="50vh"
                :options="filteredLanguages"
                :value="filters.languageId"
                @change="setLanguageId($event), fetch()"
              />
            </div>

            <base-button
              v-if="searched"
              color="cancel"
              type="button"
              class="inline-block"
              @click="clearFilters"
            >
              <div class="inline-flex items-center space-x-2">
                <fv-icon class="h-4 w-4" icon="close" />
                <span>Clear</span>
              </div>
            </base-button>
          </div>
        </form>
        <div v-if="isAdmin" class="space-x-3">
          <base-button class="inline-block" @click="setShowTemplateModal(true)">
            <div class="inline-flex items-center space-x-2">
              <font-awesome-icon icon="plus" />
              <span>Create</span>
            </div>
          </base-button>
          <base-button v-if="!searched" class="inline-block" @click="fetch">
            <div class="inline-flex items-center space-x-2">
              <font-awesome-icon icon="sync" />
              <span>Refresh</span>
            </div>
          </base-button>
        </div>
      </div>

      <JournalTemplatesRow
        v-for="template in templates"
        :key="template.id"
        class="text-sm"
        :template="template"
        :is-admin="isAdmin"
      />

      <div
        v-if="
          loading ||
          !appointmentTypes.length ||
          !animalTypes.length ||
          !languages.length
        "
        slot="tableLoading"
      >
        <spinner-overlay color="light" size="xl" :loading="loading" />
      </div>
      <div
        slot="tableFooter"
        class="flex w-full items-baseline justify-between"
      >
        <div
          v-if="
            !templates.length &&
            !loading &&
            animalTypes.length &&
            appointmentTypes.length &&
            languages.length
          "
          class="text w-full py-8 text-center"
        >
          <span>No templates match the selected filters</span>
        </div>
        <div
          v-if="templates.length"
          class="flex w-full items-baseline justify-between"
        >
          <pagination-buttons
            v-if="templatesList.prev_page_url || templatesList.next_page_url"
            :pagination-list="templatesList"
            :loading="loading"
            @previous="fetch(templatesList.prev_page_url)"
            @next="fetch(templatesList.next_page_url)"
          />
          <div>
            Viewing {{ templates.length }} of {{ templatesList.total }} entries
          </div>
        </div>
      </div>
    </table-container>
    <journal-template-modal
      v-if="showTemplateModal"
      :show="showTemplateModal"
      :is-admin="isAdmin"
      :template="selectedTemplate"
      @close="setShowTemplateModal(false), setSelectedTemplate({})"
    />

    <base-modal
      :show="showConfirmationModal"
      @close="setShowConfirmationModal(false)"
    >
      <slot>
        <h2 class="mt-2 mb-4 text-center text-xl">
          <span class="font-bold">Delete "{{ selectedTemplate.title }}" </span>
        </h2>
        <div class="mb-2 flex items-center">
          <font-awesome-icon
            icon="exclamation-triangle"
            class="mx-3 text-red-800"
          />
          <p class="p-2">Are you sure you want to delete this template?</p>
        </div>
      </slot>
      <div slot="buttons" class="flex w-full justify-end space-x-2 p-2">
        <base-button
          color="cancel"
          @click="setShowConfirmationModal(false), setSelectedTemplate({})"
        >
          Cancel
        </base-button>
        <base-button
          :loading="removeLoading"
          color="primary"
          :disabled="removeLoading"
          @click="remove"
        >
          Yes, delete template
        </base-button>
      </div>
    </base-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import JournalTemplatesRow from '@/components/journal-templates/JournalTemplatesRow';
import PaginationButtons from '@/UI/PaginationButtons';
import JournalTemplateModal from '@/components/journal-templates/JournalTemplateModal';

const HORSE_ID = 10;

export default {
  components: {
    PaginationButtons,
    JournalTemplatesRow,
    JournalTemplateModal,
  },
  props: {
    countryId: {
      type: Number,
      required: false,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      removeLoading: false,
      theads: [],
    };
  },

  computed: {
    ...mapState(['locale']),
    ...mapGetters('templates', {
      templatesList: 'getJournalTemplatesList',
      selectedTemplate: 'getSelectedTemplate',
      showTemplateModal: 'getShowTemplateModal',
      showConfirmationModal: 'getShowConfirmationModal',
      getJournalTemplate: 'getJournalTemplate',
    }),
    ...mapGetters('appointment', {
      appointmentTypes: 'getAppointmentTypes',
    }),
    ...mapGetters('animal', {
      animalTypes: 'getAnimalTypes',
    }),
    ...mapGetters('country', { languages: 'getLanguages' }),
    ...mapGetters('admin', { country: 'country' }),
    ...mapState('templates', ['filters']),

    searched() {
      return !!(
        this.filters.appointmentTypeId ||
        this.filters.animalTypeId ||
        this.filters.languageId
      );
    },
    templates() {
      return this.templatesList?.data || [];
    },
    filteredLanguages() {
      return [
        {
          id: null,
          name: 'All languages',
        },
        ...this.languages,
      ];
    },
    filteredAppointmentTypes() {
      const types = this.appointmentTypes.filter(
        type =>
          type.animal_type === +this.filters.animalTypeId ||
          (type.animal_type === null && +this.filters.animalTypeId !== HORSE_ID)
      );
      return [
        {
          id: null,
          name: 'All case types',
        },
        ...types,
      ];
    },
  },

  async mounted() {
    this.theads = [
      'ID',
      'Title',
      'Body',
      'Case type',
      'Language',
      'Animal type',
      'Latest update',
      '',
    ];
    this.loading = true;
    if (!this.languages.length) await this.fetchLanguages();
    if (this.country && !this.filters.languageId) {
      const preSelectedLanguage = this.languages.find(
        language =>
          language.language_code === this.country.localization.toLowerCase()
      );
      this.setLanguageId(preSelectedLanguage.id);
    }
    if (!this.filters.animalTypeId) this.setAnimalTypeId(1);

    if (!this.animalTypes.length) this.fetchAnimalTypes();
    this.fetchAppointmentTypes({ locale: this.locale });

    this.fetch();
  },
  methods: {
    ...mapMutations('templates', [
      'setJournalTemplatesList',
      'resetFilters',
      'setShowTemplateModal',
      'setShowConfirmationModal',
      'setSelectedTemplate',
      'setAppointmentTypeId',
      'setAnimalTypeId',
      'setLanguageId',
    ]),
    ...mapActions('templates', [
      'fetchJournalTemplates',
      'deleteJournalTemplate',
    ]),
    ...mapActions('animal', ['fetchAnimalTypes']),
    ...mapActions('appointment', ['fetchAppointmentTypes']),
    ...mapActions('country', ['fetchLanguages']),

    async fetch(pageUrl = null) {
      this.loading = true;
      this.setJournalTemplatesList([]);
      await this.fetchJournalTemplates(pageUrl)
        .then(res => {
          this.setJournalTemplatesList(res.data);
        })
        .catch(e => {
          console.log({ e });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearFilters() {
      this.resetFilters();
      this.fetch();
    },
    remove() {
      this.removeLoading = true;
      this.deleteJournalTemplate(this.selectedTemplate.id)
        .then(() => {
          this.setShowConfirmationModal(false);
          this.setSelectedTemplate({});
          this.$notify({
            group: 'primary',
            title: 'Success!',
            text: 'Template deleted!',
          });
        })
        .catch(e => {
          this.$notify({
            group: 'error',
            title: 'Something went wrong',
            text: e.message,
          });
        })
        .finally(() => {
          this.removeLoading = false;
        });
    },
  },
};
</script>
