<template>
  <default-layout>
    <JournalTemplateList :is-admin="false" />
  </default-layout>
</template>

<script>
import JournalTemplateList from '@/components/journal-templates/JournalTemplateList';

export default {
  components: {
    JournalTemplateList,
  },
};
</script>

<style lang="scss" scoped></style>
