<template>
  <base-modal :show="show" @close="!isAdmin ? $emit('close') : ''">
    <slot>
      <spinner-overlay color="light" :loading="loading" />
      <div>
        <h2 class="mb-6 mt-2 text-center text-2xl font-bold">
          <span v-if="isAdmin">
            {{
              edit ? 'Edit ' + '"' + template.title + '"' : 'Create template'
            }}
          </span>
          <span v-else>{{ template.title }}</span>
        </h2>
      </div>

      <ValidationObserver
        ref="observer"
        tag="form"
        class="mb-4 space-y-4 px-4"
        @submit.prevent=""
      >
        <div class="flex justify-between space-x-2">
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            name="animal type"
            tag="div"
            class="w-full md:w-64"
          >
            <v-select
              id="animalTypeIds"
              v-model="selectedAnimalTypes"
              name="animalTypeIds"
              class="animal-types w-full rounded text-sm text-gray-800"
              label="name"
              placeholder="Animal types"
              :multiple="true"
              :disabled="!isAdmin"
              :loading="!animalTypes.length"
              :options="animalTypes"
              :clearable="true"
              @input="animalTypesSelected($event)"
            >
              <template v-if="animalTypes.length" #open-indicator>
                <fv-icon icon="chevron-down" />
              </template>
            </v-select>
            <transition name="fade">
              <div
                v-if="errors.length"
                class="mt-1 text-sm font-semibold text-red-800"
              >
                {{ errors[0] }}
              </div>
            </transition>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            name="case type"
            tag="div"
            class="w-full md:w-64"
          >
            <custom-select
              id="appointmentTypeId"
              name="appointmentTypeId"
              size="sm"
              label-size="sm"
              label-attr="name"
              value-attr="id"
              :searchable="true"
              :options="filteredAppointmentTypes"
              :value="form.appointmentTypeId"
              data-vv-as="case type"
              :disabled="!form.animalTypeIds || !isAdmin"
              @change="form.appointmentTypeId = $event"
            />
            <transition name="fade">
              <div
                v-if="errors.length"
                class="mt-1 text-sm font-semibold text-red-800"
              >
                {{ errors[0] }}
              </div>
            </transition>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            name="language"
            tag="div"
            class="w-full md:w-64"
          >
            <custom-select
              id="languageId"
              name="languageId"
              size="sm"
              label-size="sm"
              label-attr="name"
              value-attr="id"
              :searchable="true"
              :options="filteredLanguages"
              :value="form.languageId"
              data-vv-as="language"
              :disabled="!isAdmin"
              @change="form.languageId = $event"
            />
            <transition name="fade">
              <div
                v-if="errors.length"
                class="mt-1 text-sm font-semibold text-red-800"
              >
                {{ errors[0] }}
              </div>
            </transition>
          </ValidationProvider>
        </div>

        <ValidationProvider
          v-slot="{ errors }"
          rules="required"
          name="title"
          tag="div"
        >
          <base-input
            v-if="isAdmin"
            id="title"
            v-model="form.title"
            name="title"
            placeholder="Title"
          />
          <transition name="fade">
            <div
              v-if="errors.length"
              class="mt-1 text-sm font-semibold text-red-800"
            >
              {{ errors[0] }}
            </div>
          </transition>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          rules="required"
          name="body"
          tag="div"
        >
          <div class="prose w-full max-w-full">
            <ckeditor
              v-if="isAdmin"
              id="body"
              v-model="form.body"
              :editor="editor"
              :config="editorConfig"
              name="body"
            />
            <div v-else v-html="form.body" />
          </div>
          <transition name="fade">
            <div
              v-if="errors.length"
              class="mt-1 text-sm font-semibold text-red-800"
            >
              {{ errors[0] }}
            </div>
          </transition>
        </ValidationProvider>
      </ValidationObserver>
    </slot>
    <div slot="buttons" class="flex w-full justify-end space-x-2 p-2">
      <base-button color="cancel" @click="$emit('close', false)">
        {{ isAdmin ? 'Cancel' : 'Close' }}
      </base-button>
      <base-button
        v-if="isAdmin"
        :loading="loading"
        color="primary"
        :disabled="loading"
        @click="submit"
      >
        {{ edit ? 'Update' : 'Save' }}
      </base-button>
    </div>
  </base-modal>
</template>

<script>
import '@/plugins/ckeditor';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { mapGetters, mapActions } from 'vuex';

const HORSE_ID = 10;

export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    template: {
      type: Object,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          'heading',
          '|',
          'bold',
          'italic',
          'bulletedList',
          'numberedList',
          'blockQuote',
          'link',
        ],
        placeholder: 'Body',
        link: {
          addTargetToExternalLinks: true,
        },
      },
      isValid: false,
      form: {
        appointmentTypeId: null,
        animalTypeIds: [],
        languageId: null,
        title: '',
        body: '',
      },
      selectedAnimalTypes: [],
      success: false,
    };
  },
  computed: {
    ...mapGetters('appointment', {
      appointmentTypes: 'getAppointmentTypes',
    }),
    ...mapGetters('animal', {
      animalTypes: 'getAnimalTypes',
    }),
    ...mapGetters('admin', { country: 'country' }),
    ...mapGetters('country', { languages: 'getLanguages' }),
    ...mapGetters('templates', { languageId: 'getLanguageId' }),
    edit() {
      return !!this.template?.id;
    },
    filteredLanguages() {
      return [
        {
          id: null,
          name: 'Language',
        },
        ...this.languages,
      ];
    },
    filteredAnimalTypes() {
      return [
        {
          id: null,
          name: 'Animal type',
        },
        ...this.animalTypes,
      ];
    },
    filteredAppointmentTypes() {
      const types = this.appointmentTypes.filter(
        type =>
          this.form.animalTypeIds.includes(type.animal_type) ||
          (type.animal_type === null &&
            !this.form.animalTypeIds.includes(HORSE_ID))
      );
      return [
        {
          id: null,
          name: 'All case types',
        },
        ...types,
      ];
    },
  },

  created() {
    this.setInitiaValues();
  },
  methods: {
    ...mapActions('templates', [
      'updateJournalTemplate',
      'createJournalTemplate',
    ]),
    setInitiaValues() {
      /* eslint-disable camelcase */
      this.form = {
        appointmentTypeId: +this.template?.appointment_type_id || null,
        animalTypeIds:
          this.template.animal_types?.map(animal_type => animal_type.id) || [],
        languageId:
          +this.template?.language_id ||
          (this.languageId ? this.languageId : null),
        title: this.template?.title || '',
        body: this.template?.body || '',
      };

      this.selectedAnimalTypes =
        this.template.animal_types?.map(animal_type => ({
          id: animal_type.id,
          name: animal_type.name,
        })) || [];
    },

    animalTypesSelected(animalTypes) {
      this.form.animalTypeIds = animalTypes.map(animalType => animalType.id);
    },

    async submit() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      this.loading = true;

      const payload = {
        ...this.form,
      };

      if (this.edit) {
        await this.updateJournalTemplate({
          id: this.template.id,
          data: payload,
        })
          .then(() => {
            this.success = true;
          })
          .catch(e => {
            this.error = e;
          });
      } else {
        await this.createJournalTemplate(payload)
          .then(() => {
            this.success = true;
          })
          .catch(e => {
            this.error = e;
          });
      }
      this.loading = false;

      if (this.success) {
        let text = 'Journal template ';
        text += this.edit ? 'updated' : 'saved';
        this.$notify({
          group: 'primary',
          title: 'Success!',
          text,
        });
        this.$emit('close');
        this.resetForm();
      } else {
        this.$notify({
          group: 'error',
          title: 'Something went wrong',
          text: this.error.message,
        });
      }
    },
    resetForm() {
      this.form = {
        appointmentTypeId: null,
        animalTypeIds: [],
        languageId: null,
        title: '',
        body: '',
      };
    },
  },
};
</script>
<style scoped lang="postcss">
>>> .ck-editor a {
  color: rgba(30, 64, 175, 1);
  text-decoration: underline;
}
>>> .ck-editor__editable {
  height: 42vh;
  width: 100%;
  border-radius: 0 0 0.25rem 0.25rem !important;
  padding-left: 1rem;
  color: #1a202c;
  font-size: 14px;
}
>>> .ck-editor__editable ol {
  margin-left: 0;
  list-style-type: none;
}
>>> .ck-editor__editable ul {
  margin-left: 0;
  list-style-type: none;
}
>>> .ck-editor__editable > .ck-placeholder::before {
  color: #a1aec1;
}
>>> .ck-toolbar {
  border-radius: 0.25rem 0.25rem 0 0 !important;
  padding-left: 0.75rem;
}
>>> .ck-list__item::before {
  display: none !important;
}

>>> .animal-types .vs__search {
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
}
</style>
