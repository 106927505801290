var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex flex-1 flex-col",
      staticStyle: { "min-height": "50vh" },
    },
    [
      _c(
        "table-container",
        {
          staticStyle: { "min-height": "50vh" },
          attrs: { theads: _vm.theads, loading: _vm.loading },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex flex-col justify-between space-y-2 md:w-full md:flex-row md:items-center md:space-y-0",
              attrs: { slot: "tableHeader" },
              slot: "tableHeader",
            },
            [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.fetch(null)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-col space-y-2 md:flex-row md:items-center md:space-x-3 md:space-y-0",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "w-full md:w-64" },
                        [
                          _c("custom-select", {
                            attrs: {
                              size: "sm",
                              "label-size": "sm",
                              "label-attr": "name",
                              "value-attr": "id",
                              searchable: true,
                              loading: !_vm.animalTypes.length,
                              options: _vm.animalTypes,
                              value: _vm.filters.animalTypeId,
                              placeholder: "Loading ...",
                            },
                            on: {
                              change: function ($event) {
                                _vm.setAnimalTypeId($event), _vm.fetch()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "w-full md:w-64" },
                        [
                          _c("custom-select", {
                            attrs: {
                              size: "sm",
                              "label-size": "sm",
                              "label-attr": "name",
                              "value-attr": "id",
                              searchable: true,
                              loading: !_vm.appointmentTypes.length,
                              options: _vm.filteredAppointmentTypes,
                              value: _vm.filters.appointmentTypeId,
                            },
                            on: {
                              change: function ($event) {
                                _vm.setAppointmentTypeId($event), _vm.fetch()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "w-full md:w-64" },
                        [
                          _c("custom-select", {
                            attrs: {
                              size: "sm",
                              "label-size": "sm",
                              "label-attr": "name",
                              "value-attr": "id",
                              searchable: true,
                              loading: !_vm.languages.length,
                              height: "50vh",
                              options: _vm.filteredLanguages,
                              value: _vm.filters.languageId,
                            },
                            on: {
                              change: function ($event) {
                                _vm.setLanguageId($event), _vm.fetch()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.searched
                        ? _c(
                            "base-button",
                            {
                              staticClass: "inline-block",
                              attrs: { color: "cancel", type: "button" },
                              on: { click: _vm.clearFilters },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "inline-flex items-center space-x-2",
                                },
                                [
                                  _c("fv-icon", {
                                    staticClass: "h-4 w-4",
                                    attrs: { icon: "close" },
                                  }),
                                  _c("span", [_vm._v("Clear")]),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm.isAdmin
                ? _c(
                    "div",
                    { staticClass: "space-x-3" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "inline-block",
                          on: {
                            click: function ($event) {
                              return _vm.setShowTemplateModal(true)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "inline-flex items-center space-x-2",
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "plus" },
                              }),
                              _c("span", [_vm._v("Create")]),
                            ],
                            1
                          ),
                        ]
                      ),
                      !_vm.searched
                        ? _c(
                            "base-button",
                            {
                              staticClass: "inline-block",
                              on: { click: _vm.fetch },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "inline-flex items-center space-x-2",
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "sync" },
                                  }),
                                  _c("span", [_vm._v("Refresh")]),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm._l(_vm.templates, function (template) {
            return _c("JournalTemplatesRow", {
              key: template.id,
              staticClass: "text-sm",
              attrs: { template: template, "is-admin": _vm.isAdmin },
            })
          }),
          _vm.loading ||
          !_vm.appointmentTypes.length ||
          !_vm.animalTypes.length ||
          !_vm.languages.length
            ? _c(
                "div",
                { attrs: { slot: "tableLoading" }, slot: "tableLoading" },
                [
                  _c("spinner-overlay", {
                    attrs: { color: "light", size: "xl", loading: _vm.loading },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "flex w-full items-baseline justify-between",
              attrs: { slot: "tableFooter" },
              slot: "tableFooter",
            },
            [
              !_vm.templates.length &&
              !_vm.loading &&
              _vm.animalTypes.length &&
              _vm.appointmentTypes.length &&
              _vm.languages.length
                ? _c("div", { staticClass: "text w-full py-8 text-center" }, [
                    _c("span", [
                      _vm._v("No templates match the selected filters"),
                    ]),
                  ])
                : _vm._e(),
              _vm.templates.length
                ? _c(
                    "div",
                    {
                      staticClass: "flex w-full items-baseline justify-between",
                    },
                    [
                      _vm.templatesList.prev_page_url ||
                      _vm.templatesList.next_page_url
                        ? _c("pagination-buttons", {
                            attrs: {
                              "pagination-list": _vm.templatesList,
                              loading: _vm.loading,
                            },
                            on: {
                              previous: function ($event) {
                                return _vm.fetch(
                                  _vm.templatesList.prev_page_url
                                )
                              },
                              next: function ($event) {
                                return _vm.fetch(
                                  _vm.templatesList.next_page_url
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      _c("div", [
                        _vm._v(
                          " Viewing " +
                            _vm._s(_vm.templates.length) +
                            " of " +
                            _vm._s(_vm.templatesList.total) +
                            " entries "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        2
      ),
      _vm.showTemplateModal
        ? _c("journal-template-modal", {
            attrs: {
              show: _vm.showTemplateModal,
              "is-admin": _vm.isAdmin,
              template: _vm.selectedTemplate,
            },
            on: {
              close: function ($event) {
                _vm.setShowTemplateModal(false), _vm.setSelectedTemplate({})
              },
            },
          })
        : _vm._e(),
      _c(
        "base-modal",
        {
          attrs: { show: _vm.showConfirmationModal },
          on: {
            close: function ($event) {
              return _vm.setShowConfirmationModal(false)
            },
          },
        },
        [
          _vm._t("default", function () {
            return [
              _c("h2", { staticClass: "mt-2 mb-4 text-center text-xl" }, [
                _c("span", { staticClass: "font-bold" }, [
                  _vm._v(
                    'Delete "' + _vm._s(_vm.selectedTemplate.title) + '" '
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "mb-2 flex items-center" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mx-3 text-red-800",
                    attrs: { icon: "exclamation-triangle" },
                  }),
                  _c("p", { staticClass: "p-2" }, [
                    _vm._v("Are you sure you want to delete this template?"),
                  ]),
                ],
                1
              ),
            ]
          }),
          _c(
            "div",
            {
              staticClass: "flex w-full justify-end space-x-2 p-2",
              attrs: { slot: "buttons" },
              slot: "buttons",
            },
            [
              _c(
                "base-button",
                {
                  attrs: { color: "cancel" },
                  on: {
                    click: function ($event) {
                      _vm.setShowConfirmationModal(false),
                        _vm.setSelectedTemplate({})
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "base-button",
                {
                  attrs: {
                    loading: _vm.removeLoading,
                    color: "primary",
                    disabled: _vm.removeLoading,
                  },
                  on: { click: _vm.remove },
                },
                [_vm._v(" Yes, delete template ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }