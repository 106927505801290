<template>
  <tr
    class="hover:bg-gray-200"
    :class="{ 'bg-gray-200': template.id === selectedTemplate.id }"
  >
    <table-data
      class="cursor-pointer"
      @click.native="setShowTemplateModal(true), setSelectedTemplate(template)"
    >
      <p>#{{ template.id }}</p>
    </table-data>
    <table-data
      class="cursor-pointer"
      @click.native="setShowTemplateModal(true), setSelectedTemplate(template)"
    >
      <p class="w-52 truncate">
        {{ template.title }}
      </p>
    </table-data>
    <table-data
      class="cursor-pointer"
      @click.native="setShowTemplateModal(true), setSelectedTemplate(template)"
    >
      <div class="flex flex-col space-y-1">
        <p
          class="w-52 overflow-hidden"
          style="
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          "
          v-html="template.body"
        />
      </div>
    </table-data>
    <table-data
      class="cursor-pointer"
      @click.native="setShowTemplateModal(true), setSelectedTemplate(template)"
    >
      <p>
        {{ template.appointment_type.name }}
      </p>
    </table-data>
    <table-data
      class="cursor-pointer"
      @click.native="setShowTemplateModal(true), setSelectedTemplate(template)"
    >
      <p>
        {{ template.language.name }}
      </p>
    </table-data>
    <table-data
      class="cursor-pointer"
      @click.native="setShowTemplateModal(true), setSelectedTemplate(template)"
    >
      <p>
        {{
          template.animal_types.map(animal_type => animal_type.name).join(', ')
        }}
      </p>
    </table-data>
    <table-data
      class="cursor-pointer"
      @click.native="setShowTemplateModal(true), setSelectedTemplate(template)"
    >
      <p>
        {{ format(new Date(template.updated_at), 'HH:mm yyyy-MM-dd') }}
      </p>
    </table-data>
    <table-data :class="{ 'cursor-pointer': isAdmin }">
      <font-awesome-icon
        v-if="isAdmin"
        v-tooltip="'Delete template'"
        class="text-red-800 hover:text-red-600"
        icon="trash"
        @click="setShowConfirmationModal(true), setSelectedTemplate(template)"
      />
    </table-data>
  </tr>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { format } from 'date-fns';

export default {
  props: {
    template: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      format,
      showModal: false,
      showDeleteModal: false,
    };
  },
  computed: {
    ...mapGetters('templates', {
      selectedTemplate: 'getSelectedTemplate',
    }),
  },
  methods: {
    ...mapMutations('templates', [
      'setSelectedTemplate',
      'setShowTemplateModal',
      'setShowConfirmationModal',
    ]),
  },
};
</script>
