var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "inline-flex space-x-2 items-center rounded text-lg ",
      attrs: { id: "pagination" },
    },
    [
      _c(
        "base-button",
        {
          staticClass: "flex-1",
          class: {
            "opacity-50 cursor-not-allowed":
              !_vm.paginationList.prev_page_url || _vm.loading,
          },
          attrs: { color: "light", size: "sm" },
          on: {
            click: function ($event) {
              !_vm.paginationList.prev_page_url || _vm.loading
                ? ""
                : _vm.$emit("previous", _vm.paginationList.prev_page_url)
            },
          },
        },
        [_c("fv-icon", { attrs: { icon: "chevron-left" } })],
        1
      ),
      _c("span", { staticClass: "w-1/2 text-gray-700 text-sm" }, [
        _c("span", { staticClass: "font-semibold" }, [
          _vm._v(_vm._s(_vm.paginationList.current_page)),
        ]),
        _vm._v(" / " + _vm._s(_vm.paginationList.last_page) + " "),
      ]),
      _c(
        "base-button",
        {
          staticClass: "flex-1",
          class: {
            "opacity-50 cursor-not-allowed":
              !_vm.paginationList.next_page_url || _vm.loading,
          },
          attrs: { color: "light", size: "sm" },
          on: {
            click: function ($event) {
              !_vm.paginationList.next_page_url || _vm.loading
                ? ""
                : _vm.$emit("next", _vm.paginationList.next_page_url)
            },
          },
        },
        [_c("fv-icon", { attrs: { icon: "chevron-right" } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }