<template>
  <div
    id="pagination"
    class="inline-flex space-x-2 items-center rounded text-lg "
  >
    <base-button
      color="light"
      class="flex-1"
      size="sm"
      :class="{'opacity-50 cursor-not-allowed' : !paginationList.prev_page_url || loading}"
      @click="!paginationList.prev_page_url || loading ? '' : $emit('previous', paginationList.prev_page_url)"
    >
      <fv-icon
        icon="chevron-left"
      />
    </base-button>
    <span class="w-1/2 text-gray-700 text-sm">
      <span class="font-semibold">{{ paginationList.current_page }}</span> / {{ paginationList.last_page }}
    </span>
    <base-button
      color="light"
      class="flex-1"
      size="sm"
      :class="{'opacity-50 cursor-not-allowed' : !paginationList.next_page_url || loading}"
      @click="!paginationList.next_page_url || loading ? '' : $emit('next', paginationList.next_page_url)"
    >
      <fv-icon
        icon="chevron-right"
      />
    </base-button>
  </div>
</template>

<script>
export default {
  props: {
    paginationList: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
